import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from "@material-ui/core";

import TaDialog from "../components/dialog/TaDialog";
import TaFab from "../components/fab/TaFab";
import TaTypography from '../components/typography/TaTypography';

const RegisterHelpToken = memo(({ onClose }) => {
    return (
        <TaDialog
            isOpen={true}
            title={"Como crear un BOT en Telegram?"}
            content={
                <Grid container alignContent="flex-start" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTypography>{`1) Buscar en Telegram el usuario: @BotFather`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTypography>{`2) Escribir el comando /newbot`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTypography>{`3) Escribir el nombre del BOT`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTypography>{`4) Escribir el nombre de usuario del BOT (debe terminar en Bot o _bot, por ejemplo: EjemploBot, Ejemplo_bot)`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TaTypography>{`5) Al culminar el registro, el @BotFather le otorga un token, ese token debe incluirlo en nuestro registro`}</TaTypography>
                    </Grid>
                </Grid>
            }
            actions={
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                        <TaFab
                            id="btnCloseHelpToken"
                            color="red"
                            icon="exit"
                            onClick={onClose} />
                    </Grid>
                </Grid>
            }
        />
    );
});

RegisterHelpToken.propTypes = {
    onClose: PropTypes.func,
};

export default RegisterHelpToken;