import React, { memo, useState, useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";

import { Grid, Paper, TextField, } from "@material-ui/core"

import TaFab from "../components/fab/TaFab"
import TaTypography from "../components/typography/TaTypography"
import Loader from "../components/loader/Loader"

import {
    regexTotalAppId,
    regexName,
    regexEmail,
    regexPass,
    regexTelegramToken,
} from "../helper/RegExp"

import { isOK } from "../helper/RestStatus"

import {
    postRegister,
    getTelegramInfo,
} from "./RegisterApi"

import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import useTextField from "../helper/UseTextField";
import ReCAPTCHA from "react-google-recaptcha";
import { RHFInput } from "react-hook-form-input";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RegisterHelpToken from "./RegisterHelpToken";
import LoaderToken from "../LoaderToken";

const Register = memo(({ tgId }) => {
    const [isOpenHelp, setIsOpenHelp] = useState(false);
    const [isOpenValToken, setIsOpenValToken] = useState(false);
    const { handleSubmit, register, errors, getValues, reset, setValue, watch } = useForm();
    const [isRegistered, setIsRegistered] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);
    const fnOnSubmit = async (data) => {
        try {
            setLoader(true)
            const response = await postRegister(data, tgId);
            setLoader(false)
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    setResponseMessage(response.data.message)
                    reset();
                    return setIsRegistered(true);
                }
            }
            return sbDispatch.error(response.data.message)
        } catch (error) {
            console.error('fnOnSubmit => ', error);
        }
    }

    const fnOnClickHelpToken = () => setIsOpenHelp(true)
    const fnOnCloseHelpToken = () => setIsOpenHelp(false)

    const tokenTG = useTextField('tokenTG', 'Token de Telegram', errors, "text");
    const nameBot = useTextField('nameBot', 'Nombre del BOT', errors, "text");
    const nickname = useTextField('nickname', 'Usuario', errors, "text");
    const name = useTextField('name', 'Nombre', errors, "text");
    const lastName = useTextField('lastName', 'Apellido', errors, "text");
    const email = useTextField('email', 'Correo electrónico', errors, "text");
    const pass = useTextField('password', 'Contraseña', errors, "password");
    const confPass = useTextField('confirmationPass', 'Confirmar contraseña', errors, "password");
    const watchTokenTG = watch('tokenTG');

    useEffect(() => {
        if (String(watchTokenTG).match(regexTelegramToken.value)) {
            fnGetValidateTgToken(watchTokenTG);
        }else{
            if(nameBot.value !== ""){
                return setValue('nameBot', "");
            }
        }
    }, [watchTokenTG])

    const fnGetValidateTgToken = async(token) => {
        setIsOpenValToken(true);
        const response = await getTelegramInfo(token)
        setIsOpenValToken(false);
        if(response.status === 200){
            if(response.data.ok === true){
                return setValue('nameBot', response.data.result.username)
            }
        }
        return sbDispatch.error('No se pudo validar el token de Telegram')
    }

    return (
        <Paper className="center" style={{ padding: "20px" }}>
            <Grid container alignItems="center" alignContent="flex-start" spacing={1} style={{ minHeight: "600px" }}>
                <Grid item xs={false} sm={5} md={5} lg={5} xl={5}>Imagen para algo</Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7} hidden={isRegistered}>
                    <form onSubmit={handleSubmit(fnOnSubmit)}>
                        <Grid container alignItems="center" alignContent="center" spacing={1} style={{ padding: "10px" }}>
                            <Grid item xs={10} sm={10} md={10} lg={11} xl={11} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...tokenTG}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexTelegramToken })} />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                                <HelpOutlineIcon onClick={fnOnClickHelpToken} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...nameBot}
                                    fullWidth
                                    disabled={true}
                                    inputRef={register({ required: "Requerido" })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...nickname}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexTotalAppId })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...name}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexName, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...lastName}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexName, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...email}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexEmail })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...pass}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexPass })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                <TextField
                                    {...confPass}
                                    fullWidth
                                    inputRef={register({
                                        required: "Requerido", pattern: regexPass,
                                        validate: value => (getValues("password") === value) || "Las claves no coinciden"
                                    })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }} align="center">
                                <RHFInput
                                    as={
                                        <ReCAPTCHA sitekey="6Ldoj9AZAAAAACCmHec4Hsl2kyHoscv7VtbRLmCk" />
                                    }
                                    name={'humanKey'}
                                    register={register({ required: "Requerido" })}
                                    setValue={setValue} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                                <TaFab
                                    id="btnSubmitRegister"
                                    type="submit"
                                    color="green"
                                    icon="done"
                                    disabled={loader} />
                            </Grid>
                            <Loader isLoading={loader} />
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} hidden={!isRegistered}>
                    <TaTypography align="center">{responseMessage}</TaTypography>
                </Grid>
                {isOpenHelp && <RegisterHelpToken onClose={fnOnCloseHelpToken} />}
                {isOpenValToken && <LoaderToken text={'Verificando el token de Telegram'} />}
            </Grid>
        </Paper>
    );
});

export default Register;