import {
    fnGetUrl,
    fnPostUrl,
    fnPutUrl,
} from "../axios/Api"

export const getInitialData = (country) => {
    const apiRequest = {
        endpoint: `/api/Init/Data?sCountry=${country}`,
    }
    return fnGetUrl(apiRequest)
}

export const getCompany = (nickname, id) => {
    const apiRequest = {
        endpoint: `/api/Company/?sNickname=${nickname}&nId=${id}`,
    }
    return fnGetUrl(apiRequest)
}

export const postCompany = (loginData, data, location, polygones) => {
    const body = companyModel(0, loginData, data, location, polygones)
    const apiRequest = {
        endpoint: `/api/Company`,
        body: body
    }
    return fnPostUrl(apiRequest)
}

export const putCompany = (id, loginData, data, location, polygones) => {
    const body = companyModel(id, loginData, data, location, polygones)
    const apiRequest = {
        endpoint: `/api/Company?nId=${id}`,
        body: body
    }
    return fnPutUrl(apiRequest)
}

const companyModel = (id, loginData, data, location, polygones) => {
    return {
        "listPolygon": polygones.map(x => ({ longitude: x.lng, latitude: x.lat })),
        "id": id,
        "tgId": loginData.tgid,
        "nickName": loginData.nickName,
        "idClassification": data.idClassification,
        "idCompany": data.companyId,
        "companyName": data.companyName,
        "storeName": data.storeName,
        "country": data.country,
        "state": data.state,
        "city": (data.city) ? data.city : '',
        "municipality": (data.municipality) ? data.municipality : '',
        "longitude": location.lng,
        "latitude": location.lat,
        "address1": data.address1,
        "address2": data.address2,
        "type": data.type,
        "phone1": data.phone1,
        "phone2": data.phone2,
        "facebook": data.facebook,
        "instagram": data.instagram,
        "extDescription": data.extDescription,
    }
}