import React, { useState } from "react";
import uuid from "react-uuid";

const initialState = {
    user: "",
    fullName: "",
    nickName: "",
    isLogin: false,
    companies: [],
    nameBot: "",
}

const LoginContext = React.createContext(initialState);
const LoginProvider = ({ children }) => {
    const [loginState, setLoginState] = useState(initialState);
    const setLogin = (data) => setLoginState(data);
    const setLogoff = () => setLoginState(initialState);
    const addCompany = (id, companyName, storeName) => setLoginState(preVal => ({...preVal, companies: preVal.companies.concat({id: id, name: `${companyName} (${storeName})`, uniqueKey :uuid() })}))
    const setLogState = {
        setLogin: setLogin,
        setLogoff: setLogoff,
        addCompany: addCompany,
    }
    return (
        <LoginContext.Provider value={{ loginState, setLoginState: setLogState }}>
            {children}
        </LoginContext.Provider>
    );
};


export { LoginContext, LoginProvider };