import {
    fnGetUrl,
    fnPostUrl,
    fnPutUrl,
} from "../axios/Api"

export const getPaymentMethods = () => {
    const apiRequest = {
        endpoint: `/api/PayMet/PaymentMethods/`,
    }
    return fnGetUrl(apiRequest)
}

export const postPaymentMethods = (id, nickname, data) => {

    const body = {
        "nickName": nickname,
        "idCompany": id,
        "listItem": data.map(x => {
            return {
                "id": x.id,
                "idPaymentMethod": x.idPaymentMethod,
                "currencyType": x.currencyType,
                "active": x.active,
                "accountHolder": x.accountHolder,
                "accountHolderId": x.accountHolderId,
                "phone": x.phone,
                "email": x.email,
                "bank": x.bank,
                "account": x.account,
                "accountType": x.accountType,
                "idAccount": x.idAccount,
                "isIntegrated": x.isIntegrated,
            }
        })
    }

    const apiRequest = {
        endpoint: `/api/PayMet/`,
        body: body
    }
    return fnPostUrl(apiRequest)
}