import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
} from '@material-ui/core/styles'

import {
    Typography,
} from "@material-ui/core/"

const TaTypographyStyles = makeStyles({
    typography: (props) => ({
        fontFamily: props.fontFamily,
        margin: "2px",
        fontSize: props.fontSize,
        color: props.color,
        height: props.height,
        width: props.width,
        lineHeight: "1.0",
    }),
});

const TaTypography = memo(({ fontFamily, fontSize, color, text, align, width, height, display, isTitle, noWrap, children, onClick }) => {
    const classes = TaTypographyStyles({
        fontFamily: fontFamily, fontSize: (isTitle) ? "18pt" : fontSize, color: (isTitle) ? "var(--main-text-color1)" : color,
        width: width, height: height
    })

    return <Typography onClick={(onClick) && onClick}
        noWrap={noWrap && noWrap}
        align={(isTitle) ? 'center' : align}
        className={classes.typography}
        display={display}>{(!children) ? text : children}</Typography>
});

TaTypography.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.string,
    text: PropTypes.any,
    display: PropTypes.string,
    fontFamily: PropTypes.string,
};

TaTypography.defaultProps = {
    fontSize: "12pt",
    color: "var(--main-text-color0)",
    align: "left",
    display: "initial",
    fontFamily: "Archivo",
};

export default TaTypography;