import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';

import './fonts/Archivo-Regular.ttf';
import './index.css';

import { SnackbarProvider } from "./components/snackbar/SnackbarContext"
import Snackbar from "./components/snackbar/Snackbar"
import Main from "./Main"

import { LoginProvider } from "./Login/LoginContext"

const App = () => {
    return (
        <SnackbarProvider>
            <LoginProvider>
                <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Switch>
                            <Route exact path="/"><Main /></Route>
                        </Switch>
                        <Snackbar />
                    </QueryParamProvider>
                </Router>
            </LoginProvider>
        </SnackbarProvider>
    );
};

export default App;