import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@material-ui/core/styles';

//MUI
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import Fab from '@material-ui/core/Fab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info'
import MenuIcon from '@material-ui/icons/Dehaze'
import RemoveIcon from '@material-ui/icons/Remove'
import SaveIcon from '@material-ui/icons/Save'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@material-ui/icons/Send'
import SettingsIcon from '@material-ui/icons/SettingsSharp'
import Storage from '@material-ui/icons/Storage'
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import Totalize from '@material-ui/icons/Done'
import Home from '@material-ui/icons/Home'
import Upload from '@material-ui/icons/Unarchive'
import CashIcon from '@material-ui/icons/MonetizationOn'
import CardIcon from '@material-ui/icons/CreditCard'
import List from '@material-ui/icons/ListAlt'
import Star from '@material-ui/icons/Star'
import FileCopy from '@material-ui/icons/FileCopy'
import SystemUpdate from '@material-ui/icons/SystemUpdate'
import CloudDownload from '@material-ui/icons/CloudDownload'
import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Tooltip from '@material-ui/core/Tooltip'
import Print from '@material-ui/icons/Print'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Toc from '@material-ui/icons/Toc'
import Key from '@material-ui/icons/VpnKey'
import RestoreIcon from '@material-ui/icons/Restore'
import CollectionsIcon from '@material-ui/icons/Collections';
import GridOnIcon from '@material-ui/icons/GridOn';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Sync from '@material-ui/icons/Sync'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import AddLocation from '@material-ui/icons/AddLocation';
import GridOn from '@material-ui/icons/GridOn';
import Receipt from '@material-ui/icons/Receipt';
import MergeType from '@material-ui/icons/MergeType';
import CallMade from '@material-ui/icons/CallMade';

//COMPONENTS
import EraseIcon from './icons/EraseIcon'
import EditImageIcon from './icons/EditImageIcon'
import ImageIcon from './icons/ImagesIcon'
import PercentIcon from './icons/PercentIcon'
import PriceVisorIcon from './icons/PriceVisorIcon'
import ExactPayment from './icons/ExactPayment'
import BarcodeScan from './icons/BarcodeScan'
import FE from './icons/FE'
import OneTileFilter from './icons/OneTileFilter'
import FourTileFilter from './icons/FourTileFilter'
import MultiTileFilter from './icons/MultiTileFilter'
import AccountCash from './icons/AccountCash'
import PrinterPos from './icons/PrinterPos'
import DownloadDoc from './icons/DownloadDocument'

const iconType = (type) => {
    switch (type) {
        case "add":
            return <AddIcon />
        case "backArrow":
            return <ArrowBackIcon />
        case "forwardArrow":
            return <ArrowForwardIcon />
        case "clear":
        case "exit":
            return <ClearIcon />
        case "delete":
            return <DeleteIcon />
        case "done":
            return <DoneIcon />
        case "edit":
            return <EditIcon />
        case "erase":
            return <EraseIcon />
        case "info":
            return <InfoIcon />
        case "menu":
            return <MenuIcon />
        case "percent":
            return <PercentIcon />
        case "pricevisor":
            return <PriceVisorIcon />
        case "remove":
            return <RemoveIcon />
        case "save":
            return <SaveIcon />
        case "search":
            return <SearchIcon />
        case "setting":
            return <SettingsIcon />
        case "send":
            return <SendIcon />
        case "storage":
            return <Storage />
        case "arrow":
        case "totalarrow":
            return <ArrowIcon />
        case "total":
            return <Totalize />
        case "home":
            return <Home />
        case "upload":
            return <Upload />
        case "cash":
            return <CashIcon />
        case "card":
            return <CardIcon />
        case "list":
            return <List />
        case "favorite":
            return <Star />
        case "favoriteB":
        case "editFavsSelected":
            return <StarBorderOutlined />
        case "image":
            return <ImageIcon />
        case "editImage":
            return <EditImageIcon />
        case "editImageSelected":
            return <EditImageIcon />
        case "systemUpdate":
            return <SystemUpdate />
        case "cloudDownload":
            return <CloudDownload />
        case "exactPayment":
            return <ExactPayment />
        case "FileCopy":
            return <FileCopy />
        case "camera":
            return <PhotoCamera />
        case 'user':
            return <AccountCircle />
        case 'disableUser':
            return <AccountCircle />
        case 'printer':
            return <Print />
        case 'barcodeScan':
            return <BarcodeScan />
        case '...':
            return <MoreVertIcon />
        case 'visibility':
            return <Visibility />
        case 'visibilityOff':
            return <VisibilityOff />
        case 'oneTileFilter':
            return <OneTileFilter />
        case 'fourTileFilter':
            return <FourTileFilter />
        case 'multiTileFilter':
            return <MultiTileFilter />
        case 'multiSelect':
            return <Toc />
        case 'key':
            return <Key />
        case 'FE':
            return <FE />
        case 'accountCash':
            return <AccountCash />
        case 'printerPos':
            return <PrinterPos />
        case 'restore':
            return <RestoreIcon />
        case 'mapType':
            return <CollectionsIcon />
        case 'listMode':
            return <GridOnIcon />
        case 'mergeTables':
            return <LibraryAddIcon />
        case 'clearTable':
            return <DeleteSweepIcon />
        case 'sync':
            return <Sync />
        case 'disable':
            return <NotInterestedIcon />
        case 'downloadDoc':
            return <DownloadDoc />
        case 'addLocation':
            return <AddLocation />
        case 'polygon':
            return <GridOn />
        case "merge":
            return <MergeType />
        case "document":
            return <Receipt />
        case "moveDoc":
            return <CallMade />
        default:
            return null
    }
}

const TaFabStyle = makeStyles({
    fab: {
        color: props => props.colorIcon,
        margin: props => (props.margin) ? '8px' : '0px',
        '&:hover': {
            backgroundColor: props => props.colorIcon,
            color: props => props.color,
        },
        //height: '25px',
        backgroundColor: props => props.color,
    },
})

const TaFabColor = (color) => {
    if (color === 'orange') {
        return "var(--main-bg-color2)";
    } else if (color === 'red') {
        return "var(--main-bg-color5)";
    } else if (color === 'green') {
        return "var(--main-bg-color4)";
    } else if (color === 'transparent') {
        return "transparent";
    } else if (color === 'white') {
        return "var(--main-bg-color0)";
    } else {
        return "var(--main-bg-color3)";
    }
}

const TaFab = React.memo(({ id, icon, size, disabled, onClick, textButton, color,
    noMargin, tooltip, hidden, index, download, uniqueKey, colorIcon, type, }) => {

    const props = { color: TaFabColor(color), margin: !(noMargin), colorIcon: colorIcon }
    const classes = TaFabStyle(props);

    return (
        (hidden === false)
            ? <Tooltip title={tooltip} aria-label={tooltip}>
                <Fab
                    id={id}
                    variant={(textButton) ? 'extended' : 'round'}
                    aria-label={icon}
                    data-id={index}
                    download={(download) && download}
                    size={(textButton) ? 'large' : size}
                    disabled={disabled}
                    className={classes.fab}
                    onClick={() => (onClick) && onClick(id, uniqueKey)}
                    type={(type) && type}>
                    {textButton === '' ? iconType(icon) : textButton}
                </Fab>
            </Tooltip>
            : ''
    )
})

TaFab.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    topMargin: PropTypes.bool,
    floatLeft: PropTypes.bool,
    tooltip: PropTypes.string,
    badge: PropTypes.object,
    hidden: PropTypes.bool,
    textButton: PropTypes.string,
    color: PropTypes.string,
};

TaFab.defaultProps = {
    disabled: false,
    size: "small",
    floatLeft: false,
    tooltip: '',
    badge: {
        invisible: true,
        content: '',
        color: 'primary',
        type: '',
    },
    hidden: false,
    textButton: '',
    color: "",
    colorIcon: "var(--main-bg-color0)",
};

export default TaFab;