import React, { memo } from 'react';
import PropTypes from "prop-types";

import {
    Grid,
    Paper,
    CircularProgress,
} from "@material-ui/core"

import TaTypography from "../components/typography/TaTypography"

const LoaderToken = memo(({text}) => {
    return (
        <Paper className="center" style={{ padding: "10px", height: "150px", width: "250px" }}>
            <Grid container alignContent="center" alignItems="center" justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "10px", height: "100px" }} align="center">
                    <CircularProgress />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "10px", height: "50px" }}>
                    <TaTypography fontSize="14pt" align="center">{`${text}`}</TaTypography>
                </Grid>
            </Grid>
        </Paper>
    );
});

LoaderToken.propTypes = {
    text: PropTypes.string,
};

LoaderToken.defaultProps = {
    text: 'Verificando información...',
};

export default LoaderToken;