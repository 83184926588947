import React, { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import TaTypography from "../typography/TaTypography";

import {
    List,
    ListItem,
    Grid,
} from "@material-ui/core";

import ListStyle from "../../styles/List";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const TaDropZone = memo(({ onDrop, textFile, formatAccept }) => {

    const classes = ListStyle();
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({ onDrop: onDrop, accept: formatAccept });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive
                ? <TaTypography align="center">Arraste su archivo aqui ...</TaTypography>
                : <TaTypography align="center">{`Arrastre y suelte ${textFile} aqui o clic aqui para seleccionarlo`} </TaTypography>}
            {acceptedFiles && <List
                component="nav">
                {acceptedFiles && acceptedFiles.map(x => {
                    return (
                        <ListItem
                            key={x.name}
                            classes={{ root: classes.root, selected: classes.selected }}>
                            <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{`${x.name}`}</Grid>
                            </Grid>
                        </ListItem>
                    )
                })}
            </List>}
        </div>
    )
})

export default TaDropZone;