import React, { memo, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import uuid from "react-uuid";

import {
    Grid,
    TextField,
    MenuItem,
} from "@material-ui/core"

import {
    regexCompanyId,
    regexCompanyName,
    regexAddress,
    regexPhone,
} from "../helper/RegExp";
import useTextField from "../helper/UseTextField";
import { MapsStateContext } from '../components/Maps/MapsContext';
import { RHFInput } from "react-hook-form-input";
import MapsContainer from "../components/Maps/";
import { COUNTRY_LIST, COMPANYTYPE_LIST } from "../constants/Country";
import { fnCompareStrings } from "../helper/CompareValues";
import {
    getInitialData,
    postCompany,
    putCompany,
} from "./CompanyApi";

import { isOK } from "../helper/RestStatus";
import Loader from "../components/loader/Loader";
import TaFab from "../components/fab/TaFab";
import { SnackbarContext } from "../components/snackbar/SnackbarContext";

let prevState = '';
const Company = memo(({ data, isNew, loginState, onRegister }) => {
    const { mapState } = useContext(MapsStateContext);
    const { sbDispatch } = useContext(SnackbarContext);
    const { handleSubmit, register, errors, reset, setValue, watch } = useForm();
    const [countries] = useState(COUNTRY_LIST);
    const [states, setStates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [municipalities, setMunicipalities] = useState([]);
    const [cities, setCities] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [defaultInfo, setDefaultInfo] = useState();
    const [companyTypes] = useState(COMPANYTYPE_LIST);
    const companyId = useTextField('companyId', 'Id de la empresa *', errors, "text");
    const companyName = useTextField('companyName', 'Nombre de la empresa *', errors, "text");
    const storeName = useTextField('storeName', 'Nombre de la tienda *', errors, "text");
    const companyType = useTextField('type', 'Tipo de despacho *', errors, "text");
    const companyClassification = useTextField('idClassification', 'Tipo de empresa *', errors, "text");
    const address1 = useTextField('address1', 'Dirección 1 *', errors, "text");
    const address2 = useTextField('address2', 'Dirección 2', errors, "text");
    const phone1 = useTextField('phone1', 'Teléfono 1 *', errors, "text");
    const phone2 = useTextField('phone2', 'Teléfono 2', errors, "text");
    const country = useTextField('country', 'Pais *', errors, "text");
    const state = useTextField('state', 'Estado *', errors, "text");
    const municipality = useTextField('municipality', 'Municipio', errors, "text");
    const city = useTextField('city', 'Ciudad', errors, "text");
    const facebookLink = useTextField('facebook', 'Dirección de Facebook', errors, "text");
    const instagramLink = useTextField('instagram', 'Dirección de Instagram', errors, "text");
    const extDescription = useTextField('extDescription', 'Descripción de la empresa', errors, "text");
    const watchCountry = watch('country');
    const watchState = watch('state');

    const fnOnResetData = () => {
        reset();
        setValue('country', '');
        setValue('state', '');
        setValue('companyClassification', '');
        setValue('companyType', '');
    }
    const fnOnSubmit = async (dataForm) => {
        if((dataForm.type === "1" || dataForm.type === "2") && mapState.polygones.length === 0){
            return sbDispatch.warning("Debe establecer el poligono de entrega para la empresa");
        }
        setIsLoading(true);
        let response
        if (!isNew) {
            response = await putCompany(data.id, loginState, dataForm, mapState.location, mapState.polygones)
        } else {
            response = await postCompany(loginState, dataForm, mapState.location, mapState.polygones);
        }
        setIsLoading(false);
        sbDispatch.api(response.status, response.data);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                if (isNew) {
                    fnOnResetData();
                    onRegister(response.data.data.company);
                }
            }
        }
    }

    const fnGetInitialData = async (country) => {
        const response = await getInitialData(country);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                setClassifications(response.data.data.clasificationCompany.map(x => ({ id: x.id, name: x.description, uniqueKey: uuid() })));
                switch (watchCountry) {
                    case 'VE':
                        setStates(response.data.data.dataCountry.Estados.map(x => ({ id: x.estado, name: x.estado, cities: x.ciudades, municipalities: x.municipios, uniqueKey: uuid() })));
                        if (prevState !== '') {
                            setValue('state', prevState);
                        }
                        break;
                    default:
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (data) {
            setValue('companyId', data.idCompany);
            setValue('companyName', data.companyName);
            setValue('storeName', data.storeName);
            setValue('type', String(data.type));
            setValue('idClassification', 0);
            setValue('phone1', data.phone1);
            setValue('phone2', data.phone2);
            setValue('address1', data.address1);
            setValue('address2', data.address2);
            setValue('country', data.country);
            setValue('state', data.state);
            setValue('city', data.city);
            setValue('municipality', data.municipality);
            setValue('facebook', data.facebook);
            setValue('instagram', data.instagram);
            setValue('extDescription', data.extDescription);
            setDefaultInfo({
                latitud: data.latitude,
                longitud: data.longitude,
                poligonal: data.polygon,
            })
        }
    }, [data]);

    useEffect(() => {
        if (mapState && mapState.isMapReadyToLoad && mapState.locationInfo.country !== "") {
            const { address, country, state, municipality, city } = mapState.locationInfo;
            setValue('address1', address);
            setValue('country', countries.find(x => fnCompareStrings(x.name, country)).id);
            prevState = state;
        } else {

        }
    }, [mapState]);

    useEffect(() => {
        if (watchCountry) {
            fnGetInitialData(watchCountry);
        }
    }, [watchCountry]);

    useEffect(() => {
        if (watchState) {
            try {
                const { cities, municipalities } = states.find(x => x.id === watchState);
                setCities(cities.map(x => ({ id: x, name: x, uniqueKey: uuid() })))
                setMunicipalities(municipalities.map(x => ({ id: x.municipio, name: x.municipio, uniqueKey: uuid() })))
            } catch (error) {

            }

        }
    }, [watchState]);


    useEffect(() => {
        setIsLoading(isNew)
        if (isNew) {
            fnOnResetData();
        }
    }, [isNew]);

    return (
        <form onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                <Loader isLoading={isLoading} />
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                    <MapsContainer
                        width="100%"
                        addressInformation={isNew}
                        addCurrentLocation={isNew}
                        height={400}
                        defaultInfo={defaultInfo}
                    />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    <Grid container alignItems="center" alignContent="flex-start" spacing={1}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ minHeight: "80px" }}>
                            <TextField
                                {...companyId}
                                fullWidth
                                autoFocus
                                inputRef={register({ required: "Requerido", pattern: regexCompanyId, })} />
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{ minHeight: "80px" }}>
                            <TextField
                                {...companyName}
                                fullWidth
                                inputRef={register({ required: "Requerido", pattern: regexCompanyName, })} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...storeName}
                                fullWidth
                                inputRef={register({ required: "Requerido", pattern: regexCompanyName, })} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...companyType}
                                        select
                                        fullWidth>
                                        {companyTypes.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={companyType.name}
                                register={register({ required: "Requerido" })}
                                setValue={setValue} />
                        </Grid>
                        {/*
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...companyClassification}
                                        select
                                        fullWidth>
                                        {classifications.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={companyClassification.name}
                                register={register({ required: "Requerido" })}
                                setValue={setValue} />
                        </Grid>
                        */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...address1}
                                fullWidth
                                inputRef={register({ required: "Requerido", pattern: regexAddress, })} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...address2}
                                fullWidth
                                inputRef={register({ pattern: regexAddress, })} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <TextField
                                {...phone1}
                                fullWidth
                                inputRef={register({ required: "Requerido", pattern: regexPhone, })} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <TextField
                                {...phone2}
                                fullWidth
                                inputRef={register({ pattern: regexPhone, })} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...country}
                                        select
                                        fullWidth>
                                        {countries.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={country.name}
                                register={register({ required: "Requerido" })}
                                setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...state}
                                        select
                                        fullWidth>
                                        {states.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={state.name}
                                register={register({ required: "Requerido" })}
                                setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...city}
                                        select
                                        fullWidth>
                                        {cities.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={city.name}
                                register={register()}
                                setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ minHeight: "80px" }}>
                            <RHFInput
                                as={
                                    <TextField
                                        {...municipality}
                                        select
                                        fullWidth>
                                        {municipalities.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                    </TextField>
                                }
                                name={municipality.name}
                                register={register()}
                                setValue={setValue} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...facebookLink}
                                fullWidth
                                inputRef={register()} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...instagramLink}
                                fullWidth
                                inputRef={register()} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                            <TextField
                                {...extDescription}
                                fullWidth
                                inputRef={register()}
                                multiline
                                rows={1}
                                rowsMax={20} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                            <TaFab
                                icon="save"
                                color="blue"
                                type="submit"
                                disabled={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Loader isLoading={isLoading} />
            </Grid>
        </form>
    );
});

Company.propTypes = {
    data: PropTypes.object,
};

export default Company;