import {
    fnGetUrl,
    fnPostUrl,
} from "../axios/Api"

export const postRegister = (data, tgId) => {
    const body = {
        ...data,
        "user": data.nickname,
        "phone": "",
        "country": "VE",
        "tGId": tgId,
    }
    const apiRequest = {
        endpoint: "/api/Register/Admin",
        body: body,
    }
    return fnPostUrl(apiRequest)
}

export const getTelegramInfo = (token) => {
    const apiRequest = {
        baseUrl: "https://api.telegram.org/",
        endpoint: `bot${token}/getMe`
    }

    return fnGetUrl(apiRequest)
}