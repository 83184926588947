import {
    fnPostUrl,
} from "../axios/Api"

export const postLogin = (data) => {
    const apiRequest = {
        endpoint: "/api/Login",
        body: data,
    }
    return fnPostUrl(apiRequest)
}