import React, { memo } from 'react'
import propTypes from 'prop-types'

//CSS
import { TableHeader } from './TaTableHeaderCss'

//MUI
import TableCell from '@material-ui/core/TableCell'

const TaTableCell = memo(({ header, width, align, colSpan, onClick, hidden, children }) => {
    return (
        <TableCell onClick={onClick ? onClick : undefined} colSpan={colSpan}
            style={(header === true)
                ? { ...TableHeader.header, width, textAlign: align }
                : (hidden === true)
                    ? { display: 'none' }
                    : { width, textAlign: align, padding: '5px', fontFamily: "Archivo", fontSize: "12pt", color: "var(--main-text-color0)" }} >
            {children}
        </TableCell>
    )
})

TaTableCell.propTypes = {
    header: propTypes.bool,
    width: propTypes.string,
    align: propTypes.string,
    onClick: propTypes.func,
}

TaTableCell.defaultProps = {
    header: false,
    width: '100px',
    align: 'left',
    colSpan: 1,
}

export default TaTableCell