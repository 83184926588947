import React, { memo } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import TaTypography from "../typography/TaTypography";

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
        margin: '2px',
        padding: '2px',
    },
    checked: {},
})(props => <Checkbox
    color="default" {...props} />);

const TaCheckBoxV2 = memo(({ id, onChange, checked, name, label, disabled = false }) => {
    return (
        <FormControlLabel
            control={
                <GreenCheckbox
                    id={id}
                    name={name}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                />
            }
            label={<TaTypography text={label} />}
        />
    )
})
export default TaCheckBoxV2
