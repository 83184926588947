import {
    fnGetUrl,
} from "../axios/Api"

export const getToken = (token) => {
    const apiRequest = {
        endpoint: "/api/ValidateToken?sToken=" + token,
    }

    return fnGetUrl(apiRequest)
}