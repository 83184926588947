import uuid from "react-uuid";

export const getUsersModel = (data) => data.map(x => ({ ...x, uniqueKey: uuid() }));

export const getUserDetailModel = (data) => ({
    id: (data) ? data.id : 0,
    name: (data) ? data.name : "",
    active: (data) ? data.active : false,
    uniqueKey: (data) ? data.uniqueKey : "",
    token: (data) ? data.token : "",
    rols: (data) ? data.rols : [],
})

export const getUsersRolModel = (data) => data.map(x => ({ id: x.id, name: x.descrip, uniqueKey: uuid(), active: false }));

export const postUserModel = (nickname, idCompany, data) => {
    return {
        idCompany: idCompany,
        nickname: nickname,
        idUser: data.id,
        user: data.user,
        active: data.active,
        listRol: data.rols
    }
}