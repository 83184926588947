import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import TaTable from '../components/table/TaTable'
import TaTableCell from "../components/table/TaTableCell"
import TaCheckBox from "../components/checkbox/TaCheckBox"
import NumberTextField from "../components/textfield/NumberTextField";
import {
    TableRow,
    MenuItem,
    TextField,
} from "@material-ui/core/";
import uuid from "react-uuid";
import useTextField from "../helper/UseTextField";

const columnName = [
    {
        id: 0,
        label: 'Moneda',
        width: "150px",
        align: "left",
        hidden: false,
    },
    {
        id: 1,
        label: 'Base',
        width: "50px",
        align: "center",
        hidden: false,
    },
    {
        id: 2,
        label: 'Factor',
        width: "80px",
        align: "right",
        hidden: false,
    },
    {
        id: 3,
        label: 'Operación',
        width: "80px",
        align: "left",
        hidden: false,
    },
    {
        id: 4,
        label: 'Visualizar',
        width: "80px",
        align: "center",
        hidden: false,
    },
];

const operationList = [
    {
        id: '/',
        name: 'Dividir',
        uniqueKey: uuid(),
    },
    {
        id: '*',
        name: 'Multiplicar',
        uniqueKey: uuid(),
    }
]
{/*  */ }

const CurrencyTable = memo(({ data, onChange }) => {

    const txtOperation = useTextField('operation', '', undefined, "text");

    const tableColumn = useMemo(() => columnName.map(x => <TaTableCell key={uuid()} header={true} align={'center'} width={x.width}>{x.label}</TaTableCell>), [columnName])
    const tableBody = useMemo(() => data.map(x => {
        return (
            <TableRow key={`row-${x.uniqueKey}`}>
                <TaTableCell key={`col0-${x.uniqueKey}`} align={columnName[0].align} width={columnName[0].width}>{x.name}</TaTableCell>
                <TaTableCell key={`col1-${x.uniqueKey}`} align={columnName[1].align} width={columnName[1].width}>{
                    <TaCheckBox
                        id={`col2CbxActive-${x.uniqueKey}`}
                        name="base"
                        checked={x.base}
                        onChange={onChange}
                        uniqueKey={x.uniqueKey} />
                }</TaTableCell>
                <TaTableCell key={`col2-${x.uniqueKey}`} align={columnName[2].align} width={columnName[2].width}>{
                    <NumberTextField
                        name="factor"
                        label=""
                        onChange={(e) => onChange('factor', e.floatValue, x.uniqueKey)}
                        fullWidth
                        disabled={x.base}
                        value={x.factor} />
                }</TaTableCell>
                <TaTableCell key={`col3-${x.uniqueKey}`} align={columnName[3].align} width={columnName[3].width}>{
                    <TextField
                        {...txtOperation}
                        select
                        fullWidth
                        onChange={(e) => onChange(e.target.name, e.target.value, x.uniqueKey)}
                        value={x.operation}
                        disabled={x.base}>
                        {operationList.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                    </TextField>
                }</TaTableCell>
                <TaTableCell key={`col4-${x.uniqueKey}`} align={columnName[4].align} width={columnName[4].width}>{
                    <TaCheckBox
                        id={`col2CbxVisible-${x.uniqueKey}`}
                        name="visible"
                        checked={x.visible}
                        onChange={onChange}
                        uniqueKey={x.uniqueKey} />
                }</TaTableCell>

            </TableRow>
        )
    }), [data, columnName, onChange]);

    return (
        <TaTable
            column={tableColumn}
            body={tableBody}
            usePagination={false}/>
    );
});

CurrencyTable.propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
};

export default CurrencyTable;