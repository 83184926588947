import React, { memo, useEffect, useState, useContext } from 'react';

import Register from "../Register";
import Login from "../Login";
import LoaderToken from "../LoaderToken";
import AdminPanel from "../AdminPanel";

import { useQueryParam, StringParam } from 'use-query-params';

import {
    getToken,
} from "./MainApi"

import { isOK } from "../helper/RestStatus"

import { SnackbarContext } from "../components/snackbar/SnackbarContext"
import { LoginContext } from "../Login/LoginContext";
import { MapsProvider } from '../components/Maps/MapsContext';
import { fnSetDataLogin } from "../Login/LoginSetData";
import { setBaseUrl } from "../axios/Api";

const Main = memo(() => {
    const [token] = useQueryParam('token', StringParam);
    const [sNickname] = useQueryParam('sNickname', StringParam);
    const [tokenType, setTokenType] = useState();
    const [tgId, setTgId] = useState(0);
    const { sbDispatch } = useContext(SnackbarContext);
    const { loginState, setLoginState } = useContext(LoginContext);

    const validateToken = async (token) => {
        const response = await getToken(token)
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                const { type, tgid } = response.data.data
                switch (String(type).toLowerCase()) {
                    case "registertg":
                        setTgId(tgid)
                        return setTokenType("Register");
                    case "validateadmin":
                        sbDispatch.api(response.status, response.data);
                        return setTokenType("Login");
                    case "autologin":
                    case "autologin_validateuser":
                        return setLoginState.setLogin(fnSetDataLogin(response.data.data))
                    default:
                        return setTokenType("Login");
                }
            } else {
                sbDispatch.error(response.data.message);
            }
        }
        return setTokenType("Login");
    }

    useEffect(() => {
        if (token) {
            if(sNickname != null && sNickname === 'FAHORRO'){
                setBaseUrl("https://server2.fullvisor.com:8443/SimplitStore");
            }
            validateToken(token);
        }
        return setTokenType("Login");
    }, [token, sNickname]);

    return (
        loginState.isLogin === false
            ? tokenType === "Register"
                ? <Register tgId={tgId} />
                : tokenType === "Login" || tokenType === "Validate"
                    ? <Login />
                    : <LoaderToken />
            : <MapsProvider>
                <AdminPanel />
            </MapsProvider>
    );
});

export default Main;