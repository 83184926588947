import React, { useEffect, useContext, useState, useCallback, useRef, memo } from 'react'
import uuid from 'react-uuid'
import {
    MapsDispatchContext,
    MapsStateContext
} from "./MapsContext"

import {
    initLocation,
    addMarkers,
    remMarkers,
    addPolygon,
    setLocationInfo,
    setWidth,
    setHeight,
    setDefaultInfo,
    remPolygon,
    setOptionMenu,
    setOptionMenuValue,
} from "./MapsActions";

import {
    AddLocation,
    GridOn,
} from "@material-ui/icons/"

import {
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
} from '@material-ui/lab/';

const colorLine = "#FF6600",
    optionMenu = [
        {
            key: uuid(),
            id: 0,
            toolTip: "Agregar marca",
            icon: <AddLocation />,
            active: true,
        },
        {
            key: uuid(),
            id: 1,
            toolTip: "Agregar poligono",
            icon: <GridOn />,
            active: false,
        }
    ];

const setNewPolygone = (polygone, fnClick, fnRightClick) => {
    if (newPolygone !== undefined) {
        newPolygone.setMap(null);
    }
    newPolygone = new window.google.maps.Polygon({
        paths: polygone,
        strokeColor: colorLine,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: colorLine,
        fillOpacity: 0.35,
    });
    newPolygone.setMap(map);
    polygoneListener = window.google.maps.event.addListener(newPolygone, 'click', fnClick);
    polygoneRightListener = window.google.maps.event.addListener(newPolygone, 'rightclick', fnRightClick);
}

var map,
    markers = [],
    polygones = [],
    polylines = [],
    mapListener,
    polygoneListener,
    polygoneRightListener,
    newPolygone;

const MapsContainer = memo(({
    addCurrentLocation = true,
    isMultiMarker,
    addressInformation = false,
    width,
    height,
    defaultInfo,
}) => {
    const { mapState } = useContext(MapsStateContext);
    const { mapDispatch } = useContext(MapsDispatchContext);
    const { selectedOptionMenu } = mapState
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    useEffect(() => mapDispatch(setOptionMenu(optionMenu)), [])

    //Initial
    useEffect(() => {
        const getCurrentPosition = async () => {
            await navigator.geolocation.getCurrentPosition(currentPos => {
                mapDispatch(initLocation({
                    lat: currentPos.coords.latitude,
                    lng: currentPos.coords.longitude
                }));
            })
        }
        getCurrentPosition();

        if (width) {
            mapDispatch(setWidth(width))
        }

        if (height) {
            mapDispatch(setHeight(height))
        }
    }, [mapDispatch, width, height])

    //event if map is ready to load
    useEffect(() => {
        if (mapState.isMapReadyToLoad === true) {
            map = new window.google.maps.Map(document.getElementById('map'), {
                center: mapState.location,
                zoom: 16,
            });
            if (addCurrentLocation) {
                fnAddMarker(mapState.location);
            }
            if (defaultInfo) {
                const newLocation = {
                    lat: defaultInfo.latitud,
                    lng: defaultInfo.longitud
                }

                map = new window.google.maps.Map(document.getElementById('map'), {
                    center: newLocation,
                    zoom: 16,
                });

                const markerId = uuid();
                const newMarker = new window.google.maps.Marker({
                    id: markerId,
                    position: newLocation,
                    map: map
                });

                markers.push(newMarker);

                const newDataPolygone = defaultInfo.poligonal.map(x => {
                    return {
                        id: uuid(),
                        lat: x.latitud,
                        lng: x.longitud,
                    }
                })

                if (defaultInfo.poligonal.length > 0 && newDataPolygone !== undefined) {
                    polygones = newDataPolygone
                    setNewPolygone(polygones, fnClickPolygone, fnRemPolygone);
                }

                mapDispatch(setDefaultInfo(newLocation, (newDataPolygone) ? newDataPolygone : []))
            }

            mapListener = map.addListener('click', fnClickMap);
        }
    }, [mapState.isMapReadyToLoad, defaultInfo, addCurrentLocation]);

    useEffect(() => {
        if (mapListener !== undefined) {
            window.google.maps.event.removeListener(mapListener);
            mapListener = map.addListener('click', fnClickMap);
        }
        if (polygoneListener !== undefined) {
            window.google.maps.event.removeListener(polygoneListener);
            polygoneListener = window.google.maps.event.addListener(newPolygone, 'click', fnClickPolygone);
        }
        if (polygoneRightListener !== undefined) {
            window.google.maps.event.removeListener(polygoneRightListener);
            polygoneRightListener = window.google.maps.event.addListener(newPolygone, 'rightclick', fnRemPolygone);
        }

    }, [selectedOptionMenu])

    const fnClickMap = useCallback((event) => {
        const currentLatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
        if (selectedOptionMenu === 0) {
            fnAddMarker(currentLatLng);
        } else if (selectedOptionMenu === 1) {
            fnAddPolygone(currentLatLng);
        }
    }, [selectedOptionMenu])

    const fnClickPolygone = useCallback((event) => {
        const currentLatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
        if (selectedOptionMenu === 0) {
            fnAddMarker(currentLatLng);
        }
    }, [selectedOptionMenu])

    const fnAddMarker = (latLng) => {
        if (isMultiMarker === undefined && markers.length > 0) {
            fnRemMarker();
        }

        const markerId = uuid();
        const newMarker = new window.google.maps.Marker({
            id: markerId,
            position: latLng,
            map: map
        });
        markers.push(newMarker);
        mapDispatch(addMarkers(markerId, latLng))
        if (addressInformation) {
            getAddressInformation(latLng);
        }
    }
    //function Remove Marker
    const fnRemMarker = (id) => {
        if (id !== undefined) {
            try {
                markers.find(x => x.id === id).setMap(null);
            } catch (error) {

            }
        } else {
            markers.forEach(x => {
                x.setMap(null);
            })
        }

        mapDispatch(remMarkers(id))
    }

    const getAddressInformation = (latLng) => {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'location': latLng }, function (results, status) {
            if (status === 'OK') {
                if (results.length > 0) {
                    var country,
                        state,
                        municipality,
                        city,
                        address = "",
                        firstResult,
                        addressResult,
                        stateResult,
                        countryResult;
                    try {
                        firstResult = results[0].address_components
                        //country = firstResult.find(x => x.types.includes("country")).long_name
                        //state = firstResult.find(x => x.types.includes("administrative_area_level_1")).long_name
                        municipality = firstResult.find(x => x.types.includes("administrative_area_level_2")).long_name
                        city = firstResult.find(x => x.types.includes("locality")).long_name

                        results.forEach(x => {
                            if (x.types.includes("route")) {
                                addressResult = x.address_components.find(x => x.types.includes("route")).long_name + " ";
                                address += (addressResult) && addressResult
                            } else if (x.types.includes("neighborhood")) {
                                addressResult = x.address_components.find(x => x.types.includes("neighborhood")).long_name + " ";
                                address += (addressResult) && addressResult
                            } else if (x.types.includes("postal_code")) {
                                addressResult = x.address_components.find(x => x.types.includes("postal_code")).long_name + " ";
                                address += (addressResult) && addressResult
                            } else if (x.types.includes("administrative_area_level_1")) {
                                stateResult = x.address_components.find(x => x.types.includes("administrative_area_level_1")).long_name + " ";
                                state = (stateResult) && stateResult
                            } else if (x.types.includes("country")) {
                                countryResult = x.address_components.find(x => x.types.includes("country")).long_name + " ";
                                country = (countryResult) && countryResult
                            }
                        })

                        mapDispatch(setLocationInfo({
                            country: country.trim(),
                            state: state.trim(),
                            municipality: municipality.trim(),
                            city: city.trim(),
                            address: address.trim()
                        }))

                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    }

    //function Add Marker
    const fnAddPolygone = (latLng) => {
        polygones.push(latLng)
        const polygoneId = uuid();
        setNewPolygone(polygones, fnClickPolygone, fnRemPolygone);
        mapDispatch(addPolygon(polygoneId, latLng))
    }
    //function Remove Marker
    const fnRemPolygone = () => {
        if (polygones.length > 0) {
            polygones.pop();
            setNewPolygone(polygones, fnClickPolygone, fnRemPolygone);
            mapDispatch(remPolygon());
        }
    }

    const handleCloseDialog = () => setIsOpenDialog(false);
    const handleOpenDialog = () => setIsOpenDialog(true);
    const handleOnClickDialog = useCallback((id) => mapDispatch(setOptionMenuValue(id)), []);
    return (
        <React.Fragment>
            <MapsComponent height={mapState.height} width={mapState.width} />
            <MapsMenu
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                handleOnClickDialog={handleOnClickDialog}
                dialogMenu={mapState.optionMenu}
                dialogOpen={isOpenDialog} />
        </React.Fragment>
    )
})

const MapsMenu = memo(({ handleOpenDialog, handleCloseDialog, dialogMenu, dialogOpen, handleOnClickDialog }) => {
    return (
        <div style={{ position: 'relative', top: -100, left: 20 }}>
            <SpeedDial
                ariaLabel="SpeedDial example"
                icon={<SpeedDialIcon />}
                onClose={handleCloseDialog}
                onOpen={handleOpenDialog}
                open={dialogOpen}
                direction={'right'}>
                {dialogMenu.map((options) => (
                    <SpeedDialAction
                        key={options.key}
                        icon={options.icon}
                        tooltipTitle={options.toolTip}
                        onClick={() => handleOnClickDialog(options.id)}
                        FabProps={
                            {
                                style: {
                                    backgroundColor: options.active === true ? "green" : "blue",
                                    color: "white"
                                },
                            }
                        }
                    />
                ))}
            </SpeedDial>
        </div>
    )
})

const MapsComponent = memo(({ height, divMapRef }) => {
    return <div ref={divMapRef} id="map" style={{ height: height + 50, width: '100%' }}></div>
})

export default MapsContainer;