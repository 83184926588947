import React, { memo, useCallback } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
        margin: '2px',
        padding: '2px',
    },
    checked: {},
})(props => <Checkbox
    color="default" {...props} />);

const TaCheckBox = memo(({ id, onChange, checked, label, name, disabled = false, uniqueKey }) => {
    const fnOnChange = useCallback((event) => {
        onChange(event.target.name, event.target.checked, uniqueKey)
    }, [onChange, uniqueKey])

    return (
        <FormControlLabel
            control={
                <GreenCheckbox
                    disabled={disabled && disabled}
                    id={id}
                    name={name}
                    onChange={(onChange) && fnOnChange}
                    checked={checked}
                />
            }
            label={label} />
    )
})
export default TaCheckBox
