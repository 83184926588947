import { saveAs } from 'file-saver'

const fnHeaders = (format) => {
    switch (format) {
        case 'text':
            return {
                'Accept': 'text/plain;charset=utf-8',
                'Content-Type': 'text/plain;charset=utf-8',
            }
        case 'excel':
            return {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
            }
        default:
            return {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf',
            }
    }
}

export const fnDownloadFile = (url, name, format = 'pdf') => {
    const isIOs = ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
    let response = {
        error: false,
        message: '',
        file: {
            blob: '',
            name: '',
        }
    }
    return fetch(url, {
        method: 'GET',
        headers: fnHeaders(format),
        responseType: 'blob'
    }).then(response => response.blob())
        .then(blob => {
            if (isIOs === false) {
                saveAs(blob, name)
            } else {
                response.file.blob = blob
                response.file.name = name
            }
            return response

        }).catch(error => {
            console.error('fnDownloadFile => ', error)
            response.error = true
            response.message = error
            return response
        })
}

export const fnDownloadFileV2 = (url, name, ) => {
    const isIOs = ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
    if (isIOs === false) {
        saveAs(url, name);
    }     
}