import React, { useReducer } from "react";
import {
    SNACKBAR_INIT,
} from './SnackbarConstants';

import SnackbarReducer from './SnackbarReducer';
import {
    snackbarSuccess,
    snackbarWarning,
    snackbarError,
    snackbarApi,
    snackbarClose,
} from "./SnackbarActions"

const SnackbarContext = React.createContext(SNACKBAR_INIT);
const SnackbarProvider = ({ children }) => {
    const [sbState, dispatch] = useReducer(SnackbarReducer, SNACKBAR_INIT);
    const success = (message) => dispatch(snackbarSuccess(message))
    const warning = (message) => dispatch(snackbarWarning(message))
    const error = (message) => dispatch(snackbarError(message))
    const api = (statusCode, data) => dispatch(snackbarApi(statusCode, data))
    const close = () => dispatch(snackbarClose())
    const sbDispatch = {
        success: success,
        warning: warning,
        error: error,
        api: api,
        close: close,
    }
    return (
        <SnackbarContext.Provider value={{ sbState, sbDispatch }}>
            {children}
        </SnackbarContext.Provider>

    );
};

export { SnackbarContext, SnackbarProvider };