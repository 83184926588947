import React from 'react'
import propTypes from 'prop-types'

//MUI
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const buttonStyles = makeStyles({
    root: props => ({
        margin: props.topMargin ? '12px' : '5px',
        minWidth: '100px',
        background: 'var(--main-bg-color2)',
        color: 'white',
        '&:hover': {
            background: 'var(--main-bg-color5)',
        },
    })
})

const TaButton = React.memo(({ id, label, disabled, onClick, size, variant, topMargin, index, hidden }) => {
    const buttonClasses = buttonStyles({ topMargin })

    return (
        !hidden && <Button
            id={id}
            variant={variant}
            // color="primary"
            disabled={disabled}
            onClick={onClick}
            size={size}
            classes={buttonClasses}
            // className={classNames((topMargin) && classes.topMargin)}
            data-id={index}>
            {label}
        </Button>
    )
})

TaButton.propTypes = {
    id: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    disabled: propTypes.bool.isRequired,
    onClick: propTypes.func.isRequired,
    variant: propTypes.string,
    size: propTypes.string,
    loading: propTypes.bool,
    topMargin: propTypes.bool,
    setRef: propTypes.func,
    hidden: propTypes.bool,
}

TaButton.defaultProps = {
    disabled: false,
    variant: "contained",
    size: "medium",
    topMargin: true,
    hidden: false,
}

export default TaButton