import React, { memo, useCallback } from "react";
import PropTypes from 'prop-types';
import { RHFInput } from "react-hook-form-input";
import NumberFormat from "react-number-format";
import {
    TextField,
} from "@material-ui/core";
import useTextField from "../../helper/UseTextField";

const NumberTextField = memo(({ register, setValue, name, label, errors, txtType, fullWidth, disabled, onChange, value }) => {
    const newTextField = useTextField(name, label, errors, txtType);
    const fnOnChangeValue = useCallback(e =>  ({value: (e[0].floatValue) ? e[0].floatValue : ""}), []);
    return (
        (register) 
        ? <RHFInput
            register={register}
            //mode="onChange"
            name={name}
            setValue={setValue}
            onChangeName="onValueChange"
            onChangeEvent={fnOnChangeValue}
            as={<NumberFormat customInput={TextField} {...newTextField} fullWidth={fullWidth} disabled={disabled} onChange={onChange} thousandSeparator />}
        />
        : <NumberFormat customInput={TextField} {...newTextField} fullWidth={fullWidth} disabled={disabled} onValueChange={onChange} thousandSeparator value={value}/>
    );
});

NumberTextField.propTypes = {
    register: PropTypes.object,
    setValue: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    errors: PropTypes.object,
    txtType: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

NumberTextField.defaultProps = {
    txtType: 'text',
    fullWidth: true,
    disabled: false,
}

export default NumberTextField;

