import React, { useContext } from "react";

import {
    Snackbar,
} from "@material-ui/core/";

import MuiAlert from "@material-ui/lab/Alert";
import { SnackbarContext } from "./SnackbarContext";

const TaSnackbar = () => {
    const { sbState, sbDispatch } = useContext(SnackbarContext);
    const { isOpen, variant, message } = sbState
    const onClose = () => sbDispatch.close()

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={5000}
            onClose={onClose}>
            <MuiAlert
                elevation={6}
                variant="filled"
                severity={variant}>
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default TaSnackbar;