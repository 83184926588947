import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Paper,
} from "@material-ui/core";

import TaFab from "../components/fab/TaFab";
import TaTypography from "../components/typography/TaTypography";
//import TaMenuItem from "../components/menuitem/TaMenuItem"; 

// const menuList = [
//     {
//         id: 0,
//         name: "Conciliación"
//     },
//     {
//         id: 1,
//         name: "Usuarios"
//     },
//     {
//         id: 2,
//         name: "Bancos"
//     }
// ]

const HeaderBar = memo(({ fullName, onLogout }) => {
    return (
        <Paper style={{ padding: "5px" }}>
            <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                {/* <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                    <TaMenuItem
                        id="btnMenuAdmin"
                        menu={menuList}
                        hidden={typeUser === 1}
                        onClick={onClickMenu} />
                </Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <TaTypography fontSize="14pt" color="var(--main-text-color0)">{fullName}</TaTypography>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                    <img src="https://totalaplicaciones.com/img/SimplitStore.png" alt="SimplitStore" width={"85%"} />
                </Grid>
                <Grid item xs={3} sm={2} md={4} lg={4} xl={4} align="right">
                    <TaFab
                        id="btnLogout"
                        icon="exit"
                        color="red"
                        onClick={onLogout}
                        tooltip={"Cerrar sesión"} />
                </Grid>
            </Grid>
        </Paper>
    );
});

HeaderBar.propTypes = {
    fullName: PropTypes.string,
    onLogout: PropTypes.func,
};

export default HeaderBar;