import uuid from "react-uuid";
export const addPaymentMethodModel = (selectedPayment, currentCurrency, data) => {
    return {
        ...selectedPayment,
        active: true,
        currencyType: currentCurrency.id,
        currencyName: currentCurrency.name,
        uniqueKey: uuid(),
        "id": data.id !== undefined ? data.id : 0,
        "idPaymentMethod": data.payment,
        "accountHolder": data.contactName,
        "accountHolderId": data.contactId,
        "phone": data.phone,
        "email": data.email,
        "bank": data.bankName,
        "account": data.bankAccount,
        "accountType": (data.accountType) ? data.accountType : '',
        "idAccount": data.contactId,
    }
}

export const addPaymentMethodFromApiModel = (selectedPayment, currentCurrency, data) => {
    return {
        "name": selectedPayment.name,
        active: true,
        currencyType: currentCurrency.id,
        currencyName: currentCurrency.name,
        uniqueKey: uuid(),
        "id": data.Id,
        "idPaymentMethod": data.idPaymentMethod,
        "accountHolder": data.accountHolder,
        "accountHolderId": data.accountHolderId,
        "phone": data.phone,
        "email": data.email,
        "bank": data.bank,
        "account": data.account,
        "accountType": (data.accountType) ? data.accountType : '',
        "idAccount": data.idAccount,
        "isIntegrated": data.isIntegrated,
        "idPayMetCategories": data.idPayMetCategories,
    }
}