import React, { useContext, useState, useEffect, useCallback } from 'react';
import Company from "../Company";
import HeaderBar from "../HeaderBar";
import Currency from "../Currency";
import Items from "../Items";
import PaymentMethods from "../PaymentMethods";
import Users from "../Users";
import uuid from "react-uuid";

import {
    Grid,
    TextField,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
    Paper,
} from "@material-ui/core";
import { LoginContext } from "../Login/LoginContext";
import useTextField from "../helper/UseTextField";
import TaFab from "../components/fab/TaFab";
import { fnMaxIndex } from "../helper/MaxIndex";
import { fnMinIndex } from "../helper/MinIndex";
import { getCompany } from "../Company/CompanyApi";
import { isOK } from '../helper/RestStatus';
import { SnackbarContext } from "../components/snackbar/SnackbarContext";

const stepContent = (stepId, data, isNew, loginState, onRegister, currentCoin, currentPaymentMethod) => {
    switch (stepId) {
        case 0:
            return <Company data={data} isNew={isNew} loginState={loginState} onRegister={onRegister} />
        case 1:
            return <Currency id={(data) ? data.id : 0} nickname={loginState.nickName} currentCoin={currentCoin} />;
        case 2:
            return <Items id={(data) ? data.id : 0} nickname={loginState.nickName} nameBot={loginState.nameBot} />
        case 3:
            return <PaymentMethods id={(data) ? data.id : 0} nickname={loginState.nickName} currentPaymentMethod={currentPaymentMethod} />;
        case 4:
            return <Users id={(data) ? data.id : 0} nickname={loginState.nickName} />;
        default:
            return ''
    }
}

const stepLabel = ["Datos de la empresa", "Moneda", "Productos", "Métodos de pago", "Usuarios"];

const AdminPanel = () => {
    const { loginState, setLoginState } = useContext(LoginContext);
    const { sbDispatch } = useContext(SnackbarContext);
    const [activeStep, setActiveStep] = useState(0);
    const [isNew, setIsNew] = useState(false);
    const [companyData, setCompanyData] = useState();
    const [companyId, setCompanyId] = useState();
    const [currentCoin, setCurrentCoin] = useState([]);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState([]);

    const fnOnLogout = () => setLoginState.setLogoff();
    const { fullName, nickName } = loginState;
    const txtCompanies = useTextField('companyList', 'Seleccione una empresa', undefined, "text");

    const fnOnChangeCompany = async (event) => {
        if (!isNew) {
            setCompanyId(event.target.value)
            const response = await getCompany(nickName, event.target.value);
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    setCurrentCoin(response.data.data.coin)
                    setCurrentPaymentMethod(response.data.data.paymentMethod)
                    return setCompanyData({...response.data.data.company[0], polygon: response.data.data.polygon.map(x => ({latitud: x.latitude, longitud: x.longitude}))})
                } else {
                    return sbDispatch.error(response.data.message);
                }
            }
            return sbDispatch.api(response.status, response.data);
        }
    }

    const fnUpdateCompany = useCallback(async () => {
        const response = await getCompany(nickName, companyId);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                setCurrentCoin(response.data.data.coin)
                return setCompanyData(response.data.data.company[0])
            } else {
                return sbDispatch.error(response.data.message);
            }
        }

        return sbDispatch.api(response.status, response.data);
    }, [companyId]);

    const fnOnAddCompany = () => {
        setCompanyData();
        setIsNew(true);
    }
    const fnOnRollBackCompany = () => setIsNew(false);
    const fnOnBackStep = () => setActiveStep(fnMinIndex(0, activeStep));
    const fnOnNextStep = () => setActiveStep(fnMaxIndex(stepLabel.length - 1, activeStep));

    const fnOnRegister = (data) => {
        setLoginState.addCompany(data.id, data.companyName, data.storeName);
        setIsNew(false);
    }

    useEffect(() => {
        if (loginState.companies.length === 0) {
            setIsNew(true)
        } else {
            setIsNew(false)
        }
    }, [loginState.companies])

    return (
        <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <HeaderBar fullName={fullName} onLogout={fnOnLogout} />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
                        <TextField
                            {...txtCompanies}
                            select
                            fullWidth
                            autoFocus
                            onChange={fnOnChangeCompany}
                            value={companyId}
                            disabled={isNew}>
                            {loginState.companies.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={1} xl={1} align='right'>
                        {
                            activeStep === 0
                                ? <TaFab
                                    id="btnAddCompany"
                                    color={isNew && loginState.companies.length > 0 ? "red" : "green"}
                                    icon={isNew && loginState.companies.length > 0 ? "exit" : "add"}
                                    onClick={isNew && loginState.companies.length > 0 ? fnOnRollBackCompany : fnOnAddCompany} />
                                : activeStep !== 4
                                    ? <TaFab
                                        id="btnUpdateCompany"
                                        color={"green"}
                                        icon={"sync"}
                                        onClick={fnUpdateCompany} />
                                    : ""
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {stepLabel.map((label) => <Step key={label}><StepLabel>{label}</StepLabel></Step>)}
                        </Stepper>
                        {/* <Company /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper style={{ padding: "20px", height: "475px", overflow: "auto" }}>
                            {stepContent(activeStep, companyData, isNew, loginState, fnOnRegister, currentCoin, currentPaymentMethod)}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <TaFab
                            id="btnBackStep"
                            color="orange"
                            icon="backArrow"
                            onClick={fnOnBackStep}
                            disabled={activeStep === 0}
                            hidden={activeStep === 0} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align='right'>
                        <TaFab
                            id="btnNextStep"
                            color="orange"
                            icon="forwardArrow"
                            onClick={fnOnNextStep}
                            disabled={isNew ||
                                !(companyId) ||
                                //activeStep === 1 && currentCoin.length === 0 || 
                                activeStep === stepLabel.length - 1}
                            hidden={isNew || activeStep === stepLabel.length - 1} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}></Grid>
        </Grid>
    );
};

export default AdminPanel;