import uuid from "react-uuid";

export const COUNTRY_LIST = [
    {
        id: 'VE',
        name: 'Venezuela',
        uniqueKey: uuid(),
    },
    {
        id: 'CO',
        name: 'Colombia',
        uniqueKey: uuid(),
    },
]

export const COMPANYTYPE_LIST = [
    {
        id: "0",
        name: 'PickUp',
        uniqueKey: uuid(),
    },
    {
        id: "1",
        name: 'Delivery',
        uniqueKey: uuid(),
    },
    {
        id: "2",
        name: 'PickUp y Delivery',
        uniqueKey: uuid(),
    },
]