import React, { memo, useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Loader from "../components/loader/Loader";
import { useForm } from "react-hook-form";
import useTextField from "../helper/UseTextField";
import {
    Grid,
    TextField,
    MenuItem,
    List,
    ListItem,
    Paper,
} from "@material-ui/core";

//import { getPaymentMethods, postPaymentMethods } from "./PaymentMethodsApi";
import ListStyle from "../styles/List";
import { RHFInput } from "react-hook-form-input";
import TaFab from "../components/fab/TaFab";
import { isOK } from '../helper/RestStatus';
import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import uuid from "react-uuid";
import {
    regexEmail,
    regexName,
    regexBankAccount,
    regexPhone,
} from "../helper/RegExp";
//import { getCurrencies } from "../Currency/CurrencyApi";
import TaCheckBox from "../components/checkbox/TaCheckBox";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";
import TaCheckBoxV2 from "../components/checkbox/TaCheckBoxV2";
import TaTypography from "../components/typography/TaTypography";
import {
    getUsers,
    getUserDetail,
    getUsersRol,
    postUser,
} from "./ApiUsers";

import { getUsersModel, getUserDetailModel, getUsersRolModel, postUserModel } from "./UsersModel";
import copy from "copy-to-clipboard";

const Users = memo(({ id, nickname }) => {

    const { sbDispatch } = useContext(SnackbarContext);
    const [isNew, setIsNew] = useState(false);
    const [userActive, setUserActive] = useState(false);
    const classes = ListStyle();
    const [isLoading, setIsLoading] = useState(false);
    const { handleSubmit, register, errors, getValues, reset, setValue } = useForm();
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(getUserDetailModel())
    const [rols, setRols] = useState([]);

    const userName = useTextField('userName', 'Nombre de usuario *', errors, "text");

    const fnOnSubmit = async (data) => {
        try {
            let newRequest = {
                id: user.id,
                user: data.userName,
                active: userActive,
                rols: user.rols.filter(x => x.active === true).map(x => x.id),
            }
            newRequest = postUserModel(nickname, id, newRequest)
            setIsLoading(true);
            const response = await postUser(newRequest);
            setIsLoading(false);

            if (response.status === 200) {
                if (response.data.errorCode === 0) {
                    sbDispatch.success(response.data.message);
                    reset();
                    setUserActive(false);
                    setUser(getUserDetailModel())
                    return fnGetUsers();
                }
                return sbDispatch.error(response.data.message);
            }
            return sbDispatch.error(response.data.message);
        } catch (error) {
            console.error(error)
            return sbDispatch.error('Ocurrio un error agregando al usuario');
        }

    }

    const fnOnAddUser = () => {
        reset();
        setUserActive(true);
        setUser({ ...getUserDetailModel(), rols: rols });
        setIsNew(true);
    }

    const fnOnClickUser = async (userId) => {
        setIsLoading(true);
        const response = await getUserDetail(nickname, id, userId);
        setIsLoading(false);
        if (response.status === 200) {
            await reset();
            const { name, listRol, active, token } = response.data.data
            setUser({ id: response.data.data.id, name: name, token: token, rols: rols.map(x => ({ ...x, active: listRol.findIndex(y => y === x.id) > -1 })) });
            await setValue('userName', name);
            setIsNew(false);
            setUserActive(active)
            return;
            //setUser();
        }
        return sbDispatch.error(response.data.message);
        // setValue('userName', user.name)
        // setValue('active', user.active)
        // setValue('token', '')
        // setPolitics(preValue => preValue.map(x => ({...x, active: (user.politics.findIndex(y => y.id === x.id) > -1)})))
        // setPolitics(user.politics);
        // setIsNew(false);
    }

    const fnOnClickRols = (name, value, uniqueKey) => setUser(preValue => ({
        ...preValue, rols: preValue.rols.map(x => x.uniqueKey === uniqueKey
            ? ({ ...x, active: !x.active })
            : x)
    }));

    const fnGetUsers = async () => {
        setIsLoading(true);
        const response = await getUsers(nickname, id);
        setIsLoading(false);
        if (response.status === 200) {
            if (response.data.errorCode === 0) {
                return setUsers(getUsersModel(response.data.data))
            }
        }
        return sbDispatch.error('Ocurrio un error consultando los usuarios');
    }

    const fnGetUsersRols = async () => {
        setIsLoading(true);
        const response = await getUsersRol();
        setIsLoading(false);
        if (response.status === 200) {
            if (response.data.errorCode === 0) {
                return setRols(getUsersRolModel(response.data.data))
            }
        }
        return sbDispatch.error('Ocurrio un error obteniendo los roles de usuario');
    }

    useEffect(() => {
        fnGetUsers();
        fnGetUsersRols();
    }, [])


    const isDisabledForm = useMemo(() => {
        return isNew === true ? false : user.id !== 0 ? false : true
    }, [user.id, isNew]);

    const fnOnClickCopy = (id, token) => copy(token);
    return (
        <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper style={{ height: "435px", overflow: "auto", padding: "10px" }}>
                    <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                            <TaGeneralTitle title={'Usuarios registrados'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper elevation={0} style={{ height: "310px", overflow: "auto" }}>
                                <List
                                    component="nav">
                                    {users && users.map(x => {
                                        return (
                                            <ListItem
                                                key={x.uniqueKey}
                                                classes={{ root: classes.root, selected: classes.selected }}
                                                onClick={() => fnOnClickUser(x.id)}>
                                                <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{`${x.name}`}</Grid>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Paper>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                            <TaFab
                                id="btnAddUser"
                                icon={"add"}
                                onClick={fnOnAddUser}
                                color="green" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper style={{ height: "435px", overflow: "auto", padding: "10px" }}>
                    <form onSubmit={handleSubmit(fnOnSubmit)} onKeyPress={(e) => (e.key === "Enter" && e.preventDefault())}>
                        <Grid container alignItems="center" alignContent="flex-start" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaGeneralTitle title={'Información de usuario'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    {...userName}
                                    fullWidth
                                    inputRef={register({ required: "Requerido", pattern: regexName, })}
                                    disabled={isDisabledForm} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper style={{ height: "135px", overflow: "auto", padding: "10px" }}>
                                    <List
                                        component="nav">
                                        {!isDisabledForm && (user) && user.rols.map(x => {
                                            return (
                                                <ListItem
                                                    key={x.uniqueKey}
                                                    classes={{ root: classes.root, selected: classes.selected }}>
                                                    <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"}>
                                                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>{`${x.name}`}</Grid>
                                                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>{
                                                            <TaCheckBox
                                                                id={`rolsRow-${x.uniqueKey}`}
                                                                name="active"
                                                                checked={x.active}
                                                                onChange={fnOnClickRols}
                                                                uniqueKey={x.uniqueKey} />
                                                        }</Grid>
                                                    </Grid>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TaCheckBoxV2
                                    name={'active'}
                                    checked={userActive}
                                    label={'Activo'}
                                    disabled={isDisabledForm}
                                    onChange={() => setUserActive(preValue => !preValue)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="right">
                                <TaFab
                                    id="btnSaveUser"
                                    icon={"save"}
                                    type="submit"
                                    disabled={isLoading || isDisabledForm} />
                            </Grid>
                            <Grid item xs={8} sm={9} md={10} lg={10} xl={10} hidden={user.token === ""}>
                                <TaTypography text={`Token: ${user.token}`} noWrap={true} />
                            </Grid>
                            <Grid item xs={4} sm={3} md={2} lg={2} xl={2} align="right" hidden={user.token === ""}>
                                <TaFab
                                    id="btnCopyToken"
                                    icon="FileCopy"
                                    onClick={fnOnClickCopy}
                                    uniqueKey={user.token}/>
                            </Grid>
                            <Loader isLoading={isLoading} />
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
});

Users.propTypes = {
    id: PropTypes.number,
    nickname: PropTypes.string,
};

export default Users;