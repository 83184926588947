import {
    fnGetUrl,
    fnPostUrl,
    fnPutUrl,
} from "../axios/Api"

export const getPlantilla = (id, nickname) => {
    const apiRequest = {
        endpoint: `/api/Item/TemplateItem?nIdCompany=${id}&sNickname=${nickname}`,
    }
    return fnGetUrl(apiRequest)
}

export const postItem = (id, nickname, data) => {

    const body = {
        "nickName": nickname,
        "idCompany": id,
        "listItem": data,
    }
    const apiRequest = {
        endpoint: `/api/Item/`,
        body: body,
    }
    return fnPostUrl(apiRequest)
}