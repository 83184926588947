import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";

import {
    Grid,
    Paper,
    TextField,
} from "@material-ui/core"

import Loader from "../components/loader/Loader"
import TaFab from "../components/fab/TaFab"

import {
    regexTotalAppIdLogin,
    regexPass,
} from "../helper/RegExp";

import {
    isOK,
} from "../helper/RestStatus";

import {
    postLogin,
} from "./LoginApi";

import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import { LoginContext } from "./LoginContext";
import useTextField from "../helper/UseTextField";
import { fnSetDataLogin } from "./LoginSetData";

const Login = () => {
    const { handleSubmit, register, errors } = useForm();
    const [loader, setLoader] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);
    const { setLoginState } = useContext(LoginContext);

    const fnOnSubmit = async (data) => {
        try {
            setLoader(true)
            const response = await postLogin(data);
            setLoader(false)
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    return setLoginState.setLogin(fnSetDataLogin(response.data.data))
                }
            }
            return sbDispatch.error(response.data.message)
        } catch (error) {
            console.error('onSubmit => ', error)
        }
    };
    const user = useTextField('user', 'Usuario', errors, "text");
    const pass = useTextField('password', 'Contraseña', errors, "password");
    return (
        <Paper className="center" style={{ padding: "20px" }}>
            <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <form onSubmit={handleSubmit(fnOnSubmit)}>
                        <Grid container alignContent="center" alignItems="center" spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                                <img src="https://totalaplicaciones.com/img/SimplitStore.png" alt="SimplitStore" width={"75%"} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Grid container  alignContent="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                        <TextField
                                            {...user}
                                            fullWidth
                                            inputRef={register({ required: "Requerido", pattern: regexTotalAppIdLogin, })} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                                        <TextField
                                            {...pass}
                                            fullWidth
                                            inputRef={register({ required: "Requerido", pattern: { ...regexPass, message: "Contraseña invalida" }, })} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                                        <TaFab
                                            id="btnLogin"
                                            icon="done"
                                            color="green"
                                            type="submit" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Loader isLoading={loader} />
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Login;