import {
    MAPS_INITLOCATION,
    MAPS_ADDMARKERS,
    MAPS_REMMARKERS,
    MAPS_ADDPOLYGON,
    MAPS_SETLOCATIONINFO,
    MAPS_SETVALUE,
    MAPS_SETDEFAULTINFO,
    MAPS_REMPOLYGON,
    MAPS_SETOPTIONMENU,
    MAPS_SETOPTIONMENUVALUE,
} from "./MapsContants"

const MapsReducer = (state, action) => {
    switch (action.type) {
        case MAPS_INITLOCATION:
            return {
                ...state,
                location: {
                    ...state.location,
                    ...action.location,
                },
                isMapReadyToLoad: true,
            }
        case MAPS_ADDMARKERS:
            return {
                ...state,
                markers: state.markers.concat({ id: action.id, ...action.location }),
                location: {
                    ...state.location,
                    ...action.location,
                },
            }
        case MAPS_REMMARKERS:
            if (action.id === undefined) {
                return {
                    ...state,
                    markers: []
                }
            } else {
                return {
                    ...state,
                    markers: state.markers.filter(x => x.id !== action.id)
                }
            }
        case MAPS_ADDPOLYGON:
            return {
                ...state,
                polygones: state.polygones.concat({ id: action.id, ...action.location })
            }

        case MAPS_SETLOCATIONINFO:

            return {
                ...state,
                locationInfo: {
                    ...state.locationInfo,
                    ...action.info,
                }
            }

        case MAPS_SETVALUE:

            return {
                ...state,
                [action.id]: action.value
            }

        case MAPS_SETDEFAULTINFO:
            return {
                ...state,
                location: {
                    ...state.location,
                    ...action.latLng
                },
                polygones: action.polygone,
            }

        case MAPS_REMPOLYGON:
            return {
                ...state,
                polygones: state.polygones.filter((x,i) => i !== (state.polygones.length - 1))
            }

        case MAPS_SETOPTIONMENU:

            return {
                ...state,
                optionMenu: action.data,
            }

        case MAPS_SETOPTIONMENUVALUE:
            return {
                ...state,
                optionMenu: state.optionMenu.map(x => {
                    return {
                        ...x,
                        active: (x.id === action.id),
                    }
                }),
                selectedOptionMenu: action.id
            }

        default:
            return state
    }
}

export default MapsReducer