import React, { memo, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import TaButton from '../components/button/TaButton';
import Loader from "../components/loader/Loader";
import { getPlantilla, postItem } from "./ItemApi";
import { isOK } from "../helper/RestStatus";
import { SnackbarContext } from "../components/snackbar/SnackbarContext";

import {
    Grid,
    Paper,
} from "@material-ui/core";
import TaDropZone from "../components/DropZone";
import TaTypography from "../components/typography/TaTypography";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";
import { fnDownloadFileV2 } from "../helper/DownloadFile";
import { ExcelRenderer } from 'react-excel-renderer';
import TaFab from "../components/fab/TaFab";
import { fnGetBaseUrl } from "../axios/Api";

const Items = memo(({ id, nickname, nameBot }) => {

    const [isLoading, setIsLoading] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);
    const [itemData, setItemData] = useState([]);
    const fnOnClickDownloadFile = async () => {
        setIsLoading(true);
        const response = await getPlantilla(id, nickname);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                fnDownloadFileV2(`${fnGetBaseUrl()}${response.data.data.downloadPath}`, "SimplitStoreItem");
            } else {
                sbDispatch.error(response.data.message);
            }
        } else {
            sbDispatch.api(response.status, response.data);
        }
        setIsLoading(false);
    }

    const fnOnSaveItem = async () => {
        // if (itemData.length === 0) {
        //     return sbDispatch.warning('No hay productos cargados en la hoja');
        // }
        setIsLoading(true);
        const response = await postItem(id, nickname, itemData);
        setIsLoading(false);
        sbDispatch.api(response.status, response.data);
    }

    const fnOnDrop = useCallback((file) => {
        ExcelRenderer(file[0], (err, resp) => {
            if (err) {
                return sbDispatch.message(err);
            }
            try {
                setItemData(resp.rows.filter((x, i) => i > 0).map(x => {
                    return {
                        "itemCode": (x[0] !== undefined) ? x[0] : '',
                        "itemName": (x[1] !== undefined) ? x[1] : '',
                        "category": (x[2] !== undefined) ? x[2] : '',
                        "exist": (x[3] !== undefined) ? x[3] : '',
                        "price": (x[4] !== undefined) ? x[4] : '',
                        "taxPercent": (x[5] !== undefined) ? x[5] : '',
                        "presentation": (x[6] !== undefined) ? x[6] : '',
                        "itemDescription": (x[7] !== undefined) ? x[7] : '',
                        "facebook": (x[8] !== undefined) ? x[8] : '',
                        "instagram": (x[9] !== undefined) ? x[9] : '',
                        "active": (x[10] !== undefined) ? x[10] : '',
                    }
                }))
            } catch (error) {
                console.error('fnOnDrop error => ', error);
                return sbDispatch.message('Error leyendo la información cargada, por favor verificar la información y cargar nuevamente');
            }
        });

    }, [])
    return (
        nameBot === "Farmahorro"
            ? <Grid container alignItems="center" alignContent="center" spacing={1} style={{ height: "360px", overflow: "auto", padding: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaTypography align="center">{`Opción no disponible para @Farmahorro, existe un proceso de sincronización`}</TaTypography>
                </Grid>
            </Grid>
            : <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Paper style={{ height: "360px", overflow: "auto", padding: "20px" }}>
                        <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaGeneralTitle title={'Información'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Debe descargar la plantilla en excel presionando el boton: Descargar plantilla`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`NO debe editar el formato de Excel: Si cambia el nombre de la hoja, los nombres u ordenes de las columnas no podra cargar los productos`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Las columnas obligatorias estan indicadas con un *`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Los precios a cargar deben ser acordes a la moneda base establecida`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Los precios deben incluir impuesto (Si el producto es exento, no aplica)`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Al tener la hoja lista, arraste el archivo en el lugar indicado o haga clic para poder seleccionarlo`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Clic al boton de guardar y sus productos estaran cargados en la plataforma`}</TaTypography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography>{`Si desea actualizar productos descargue nuevamente la plantilla y repetir el proceso`}</TaTypography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Grid container spacing={1} style={{ minHeight: "390px" }} direction="column" alignContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                            <TaButton
                                id="btnDownloadFile"
                                label="Descargar plantilla"
                                onClick={fnOnClickDownloadFile} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TaDropZone onDrop={fnOnDrop} textFile='la plantilla' formatAccept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={itemData.length === 0}>
                            <TaTypography>{`Cantidad de productos cargados: ${itemData.length}`}</TaTypography>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                    <TaFab
                        id="btnSaveItem"
                        icon={"save"}
                        onClick={fnOnSaveItem} />
                </Grid>
                <Loader isLoading={isLoading} />
            </Grid>
    );
});

Items.propTypes = {
    id: PropTypes.number,
    nickname: PropTypes.string,
};

export default Items;