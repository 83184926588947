import {
    fnGetUrl,
    fnPostUrl,
    fnPutUrl,
} from "../axios/Api"

export const getCurrencies = () => {
    const apiRequest = {
        endpoint: `/api/Coin/CurrencyType`,
    }
    return fnGetUrl(apiRequest)
}

export const postCurrencies = (id, nickname, data) => {
    const body = {
        nickName: nickname,
        idCompany: id,
        listCoin: data.map(x => ({idType: x.id, typeOper: x.operation, isBase: x.base, factor: x.factor, active: x.visible }))

    }
    const apiRequest = {
        endpoint: `/api/Coin/`,
        body: body,
    }

    return fnPostUrl(apiRequest)
}