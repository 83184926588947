import { useEffect, useState } from 'react';
import {
    textfieldStyles,
    textfieldLabelStyles,
} from "../styles/TextField";

const useTextField = (name, label, errors, type, maxLength) => {
    const textfieldClasses = textfieldStyles();
    const textfieldLabelClasses = textfieldLabelStyles();
    const [textfield, setTextfield] = useState({name: name});
    useEffect(() => {
        setTextfield({
            name: name,
            label: label,
            helperText: errors ? (name in errors) && errors[name].message : '',
            error: errors && (name in errors),
            InputProps: {
                classes: textfieldClasses,
            },
            InputLabelProps: {
                classes: textfieldLabelClasses,
                shrink: true,
            },
            inputProps:{ maxLength: (maxLength) && maxLength },
            type: type,
            autoComplete: "new-password"
        })
    }, [errors]);
    return textfield;
}

export default useTextField;