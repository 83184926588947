import React, { memo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { getCurrencies, postCurrencies } from "./CurrencyApi";
import {
    Grid,
    TextField,
    MenuItem,
} from "@material-ui/core"
import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import { isOK } from "../helper/RestStatus";
import uuid from "react-uuid";
import CurrencyTable from "./CurrencyTable";
import useTextField from "../helper/UseTextField";
import TaFab from "../components/fab/TaFab";
import Loader from "../components/loader/Loader";

const Currency = memo(({ id, nickname, currentCoin }) => {

    const { sbDispatch } = useContext(SnackbarContext);
    const [currencies, setCurrencies] = useState([]);
    const [currencyId, setCurrencyId] = useState();
    const [currencyData, setCurrencyData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const txtCurrency = useTextField('currency', 'Seleccione una moneda', undefined, "text");
    const fnOnAddCurrency = () => {
        const currentCurrency = currencies.find(x => x.id === currencyId);
        if (!currencyData.find(x => x.id === currencyId)) {
            const newData = {
                id: currentCurrency.id,
                name: currentCurrency.name,
                base: false,
                uniqueKey: uuid(),
                factor: 1,
                operation: '',
                visible: true,
            }
            return setCurrencyData(preVal => preVal.concat(newData));
        }

        return sbDispatch.warning('La moneda ya fue agregada anteriormente');
    }
    const fnOnChangeCurrency = (event) => setCurrencyId(event.target.value);
    const fnOnChangeTable = (name, value, uniqueKey) => setCurrencyData(preval => preval.map(x => x.uniqueKey === uniqueKey
        ? name === 'base' ? ({ ...x, [name]: value, factor: 1, operation: '', }) : ({ ...x, [name]: value })
        : name === 'base' ? ({ ...x, base: false }) : x));

    const fnOnSaveCurrency = async () => {
        setIsLoading(true);
        const response = await postCurrencies(id, nickname, currencyData)
        setIsLoading(false);
        return sbDispatch.api(response.status, response.data)
    }
    useEffect(() => {
        const fnGetCurrencies = async () => {
            setIsLoading(true);
            const response = await getCurrencies();
            setIsLoading(false);
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    return setCurrencies(response.data.data.map(x => ({ id: x.id, name: `${x.coin} (${x.code} ${x.symbol})`, uniqueKey: uuid() })));
                }

                return sbDispatch.error(response.data.message)
            }

            return sbDispatch.api(response.status, response.data)
        }
        fnGetCurrencies();
    }, [])

    useEffect(() => {
        if(currencies.length > 0 && currentCoin.length > 0){
            setCurrencyData(currentCoin.map(x => {
                const currentCurrency = currencies.find(y => y.id === x.idType)
                return {
                    ...currentCurrency,
                    base: x.isBase,
                    uniqueKey: uuid(),
                    factor: x.factor,
                    operation: x.typeOper,
                    visible: x.active,
                }
            }))
        }
    }, [currencies])

    return (
        <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
            <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
                <TextField
                    {...txtCurrency}
                    select
                    fullWidth
                    autoFocus
                    onChange={fnOnChangeCurrency}
                    value={currencyId}>
                    {currencies.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
                <TaFab
                    id="btnAddCurrency"
                    color={"green"}
                    icon={"add"}
                    onClick={fnOnAddCurrency} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CurrencyTable data={currencyData} onChange={fnOnChangeTable} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                <TaFab
                    id="btnSaveCurrency"
                    icon={"save"}
                    onClick={fnOnSaveCurrency} />
            </Grid>
            <Loader isLoading={isLoading}/>
        </Grid>
    );
});

Currency.propTypes = {
    id: PropTypes.number,
    nickname: PropTypes.string,
};

export default Currency;