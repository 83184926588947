import React, { memo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Loader from "../components/loader/Loader";
import { useForm } from "react-hook-form";
import useTextField from "../helper/UseTextField";
import {
    Grid,
    TextField,
    MenuItem,
    List,
    ListItem,
    Paper,
} from "@material-ui/core";

import { getPaymentMethods, postPaymentMethods } from "./PaymentMethodsApi";
import ListStyle from "../styles/List";
import { RHFInput } from "react-hook-form-input";
import TaFab from "../components/fab/TaFab";
import { isOK } from '../helper/RestStatus';
import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import uuid from "react-uuid";
import {
    regexEmail,
    regexName,
    regexBankAccount,
    regexPhone,
} from "../helper/RegExp";
import { getCurrencies } from "../Currency/CurrencyApi";
import TaCheckBox from "../components/checkbox/TaCheckBox";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";
import { addPaymentMethodModel, addPaymentMethodFromApiModel } from "./PaymentMethodsModel"

const accountTypes = [
    {
        id: "corriente",
        name: "Corriente",
        uniqueKey: uuid(),
    },
    {
        id: "ahorro",
        name: "Ahorro",
        uniqueKey: uuid(),
    }
]

const initialPaymentState = {
    "id": 0,
    "idPayMetCategories": 0,
    "name": "",
    "currencyType": null
}

const PaymentMethods = memo(({ id, nickname, currentPaymentMethod }) => {

    const { sbDispatch } = useContext(SnackbarContext);
    const classes = ListStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState(initialPaymentState);
    const { handleSubmit, register, errors, getValues, reset, setValue, watch } = useForm();

    const payment = useTextField('payment', 'Métodos de pago *', errors, "text");
    const currency = useTextField('currencyId', 'Moneda *', errors, "text");
    const bankName = useTextField('bankName', 'Nombre del banco', errors, "text");
    const bankAccount = useTextField('bankAccount', 'Número de cuenta', errors, "text");
    const accountType = useTextField('accountType', 'Tipo de cuenta', errors, "text");
    const email = useTextField('email', 'Correo', errors, "text");
    const phone = useTextField('phone', 'Teléfono', errors, "text");
    const contactName = useTextField('contactName', 'Titular de la cuenta', errors, "text");
    const contactId = useTextField('contactId', 'Identificación del titular / Switf', errors, "text");
    const watchPayment = watch('payment');

    const fnOnSubmit = async (data) => {
        try {
            const currentCurrency = currencies.find(x => x.id === data.currencyId);
            setSelectedPayments(preVal => preVal.concat(addPaymentMethodModel(selectedPayment, currentCurrency, data)));
            reset()
            setValue('payment', '');
            setValue('currencyId', '');
            setValue('accountType', '');
        } catch (error) {
            return sbDispatch.error('Ocurrio un error agregando el método de pago');
        }

    }

    const fnOnSavePaymentMethods = async () => {
        setIsLoading(true)
        const response = await postPaymentMethods(id, nickname, selectedPayments)
        setIsLoading(false)
        if (response.status === 201) {
            if (response.data.errorCode === 0) {
                return sbDispatch.success(response.data.message)
            }

        }
        return sbDispatch.error(response.data.message)
    }


    const fnOnChangePaymentMethod = (name, value, uniqueKey) => setSelectedPayments(preval => preval.map(x => x.uniqueKey === uniqueKey
        ? ({ ...x, [name]: value })
        : x))

    useEffect(() => {
        const fnGetPaymentMethods = async () => {
            setIsLoading(true);
            let response = await getPaymentMethods();
            setIsLoading(false);
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    const selectedPayments = response.data.data.map(x => ({ ...x, uniqueKey: uuid() }))
                    setPayments(selectedPayments);
                    setIsLoading(true);
                    response = await getCurrencies();
                    setIsLoading(false);
                    if (isOK(response.status)) {
                        if (response.data.errorCode === 0) {
                            const selectedCurrencies = response.data.data.map(x => ({ id: x.id, name: `${x.coin} (${x.code} ${x.symbol})`, uniqueKey: uuid() }))
                            setCurrencies(selectedCurrencies);
                            if (currentPaymentMethod.length > 0) {
                                const userPayments = currentPaymentMethod.map(x => {
                                    const selectedPayment = selectedPayments.find(y => y.id === x.idPaymentMethod)
                                    const currentCurrency = selectedCurrencies.find(y => y.id === x.currencyType);
                                    return addPaymentMethodFromApiModel(selectedPayment, currentCurrency, x)
                                })
                                return setSelectedPayments(userPayments)
                            }
                            return;
                        }
                        return sbDispatch.error(response.data.message)
                    }
                    return sbDispatch.api(response.status, response.data)
                }
                return sbDispatch.error(response.data.message);
            }
            return sbDispatch.api(response.status, response.data);
        };
        fnGetPaymentMethods();
    }, []);

    useEffect(() => {
        const currentPayment = payments.find(x => x.id === watchPayment)
        setSelectedPayment(currentPayment !== undefined ? currentPayment : initialPaymentState)
        if (currentPayment !== undefined) {
            if (currentPayment.currencyType !== null) {
                setValue('currencyId', currentPayment.currencyType);
            }
        }
    }, [watchPayment]);

    const fnOnRemovePaymentMethod = (id, uniqueKey) => setSelectedPayments(preVal => preVal.filter(x => x.uniqueKey !== uniqueKey));
    return (
        <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper style={{ height: "355px", overflow: "auto", padding: "20px" }}>
                    <form onSubmit={handleSubmit(fnOnSubmit)}>
                        <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <TaGeneralTitle title={'Agregar métodos de pago'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <RHFInput
                                    as={
                                        <TextField
                                            {...payment}
                                            select
                                            fullWidth>
                                            {payments.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                        </TextField>
                                    }
                                    name={payment.name}
                                    register={register({ required: "Requerido" })}
                                    setValue={setValue} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <RHFInput
                                    as={
                                        <TextField
                                            {...currency}
                                            select
                                            fullWidth>
                                            {currencies.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                        </TextField>
                                    }
                                    name={currency.name}
                                    register={register({ required: "Requerido" })}
                                    setValue={setValue} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || (selectedPayment.idPayMetCategories !== 2 && selectedPayment.idPayMetCategories !== 3)}>
                                <TextField
                                    {...bankName}
                                    fullWidth
                                    inputRef={register()} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || selectedPayment.idPayMetCategories !== 2}>
                                <TextField
                                    {...bankAccount}
                                    fullWidth
                                    inputRef={register({ pattern: regexBankAccount, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || selectedPayment.idPayMetCategories !== 2}>
                                <RHFInput
                                    as={
                                        <TextField
                                            {...accountType}
                                            select
                                            fullWidth>
                                            {accountTypes.map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)}
                                        </TextField>
                                    }
                                    name={accountType.name}
                                    register={register()}
                                    setValue={setValue} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || (selectedPayment.idPayMetCategories !== 2 && selectedPayment.idPayMetCategories !== 3)}>
                                <TextField
                                    {...contactId}
                                    fullWidth
                                    inputRef={register()} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || selectedPayment.idPayMetCategories !== 2}>
                                <TextField
                                    {...contactName}
                                    fullWidth
                                    inputRef={register({ pattern: regexName, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || (selectedPayment.idPayMetCategories !== 1)}>
                                <TextField
                                    {...email}
                                    fullWidth
                                    inputRef={register({ pattern: regexEmail, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={selectedPayment.id === 0 || (selectedPayment.idPayMetCategories !== 3)}>
                                <TextField
                                    {...phone}
                                    fullWidth
                                    inputRef={register({ pattern: regexPhone, })} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                                <TaFab
                                    id="btnAddPaymentMethods"
                                    icon={"add"}
                                    color={"green"}
                                    type="submit" />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper style={{ height: "355px", overflow: "auto", padding: "20px" }}>
                    <Grid container alignItems="flex-start" alignContent="flex-start" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                            <TaGeneralTitle title={'Métodos de pago'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List
                                component="nav">
                                {selectedPayments && selectedPayments.map(x => {
                                    return (
                                        <ListItem
                                            key={x.uniqueKey}
                                            classes={{ root: classes.root, selected: classes.selected }}>
                                            <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"}>
                                                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                                                    <TaCheckBox
                                                        id={`paymentMethodRow-${x.uniqueKey}`}
                                                        name="active"
                                                        checked={x.active}
                                                        onChange={fnOnChangePaymentMethod}
                                                        uniqueKey={x.uniqueKey} />
                                                </Grid>
                                                <Grid item xs={7} sm={8} md={8} lg={9} xl={9}>
                                                    <Grid container alignItems="center" alignContent={"center"}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{`${x.name}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{`${x.currencyName}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={x.idPayMetCategories !== 2 && x.idPayMetCategories !== 3}>{`${x.bank}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={x.idPayMetCategories !== 2}>{`${x.account}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={x.idPayMetCategories !== 2}>{`${x.accountType}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={x.idPayMetCategories !== 2 && x.idPayMetCategories !== 3}>{`${x.accountHolderId}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={x.idPayMetCategories !== 2}>{`${x.accountHolder}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} hidden={x.idPayMetCategories !== 1}>{`${x.email}`}</Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} hidden={x.idPayMetCategories !== 3}>{`${x.phone}`}</Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                                                    <TaFab
                                                        id="btnRemovePaymentMethods"
                                                        icon={"clear"}
                                                        color={"red"}
                                                        uniqueKey={x.uniqueKey}
                                                        onClick={fnOnRemovePaymentMethod} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                <TaFab
                    id="btnSavePaymentMethods"
                    icon={"save"}
                    onClick={fnOnSavePaymentMethods} />
            </Grid>
            <Loader isLoading={isLoading} />
        </Grid>
    );
});

PaymentMethods.propTypes = {
    id: PropTypes.number,
    nickname: PropTypes.string,
    currentPaymentMethod: PropTypes.array,
};

PaymentMethods.defaultProps = {
    currentPaymentMethod: [],
}

export default PaymentMethods;