import axios from 'axios';

var baseUrl = "https://api.simplitstore.com:8443",
//var baseUrl = "https://server2.fullvisor.com:8443/SimplitStore",
    bearerToken = "",
    authBasicHeader = {
        username: "",
        password: "",
    },
    timeout = 60000;

export const setBaseUrl = (value) => baseUrl = value
export const setBasicAuth = (user, pass = "") => {
    authBasicHeader = {
        username: user,
        password: pass
    }
}

export const fnGetUrl = async (apiRequest) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    if (apiRequest.useBasic) {
        return await axios.get(urlEndpoint, {
            auth: {
                ...authBasicHeader
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.get(urlEndpoint, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout,
        })
            .then(response => response)
            .catch(error => handleError(error))
    }

}

export const fnPostUrl = async (apiRequest) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`

    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }
    return await axios.post(urlEndpoint, apiRequest.body,
        (apiRequest.useBasic)
            ? {
                auth: {
                    ...authBasicHeader
                }
            }
            : {})
        .then(response => response)
        .catch(error => handleError(error))

    // if (apiRequest.useBasic) {

    // } else {
    //     return await axios.post(baseUrl + apiRequest.endpoint, apiRequest.body)
    //         .then(response => response)
    //         .catch(error => handleError(error))
    // }
}

export const fnPutUrl = async (apiRequest) => {
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    return await axios.put(urlEndpoint, apiRequest.body,
        (apiRequest.useBasic)
            ? {
                auth: {
                    ...authBasicHeader
                }
            } : {})
        .then(response => response)
        .catch(error => handleError(error))

    // if (apiRequest.useBasic) {

    // } else {
    //     return await axios.put(baseUrl + apiRequest.endpoint, apiRequest.body)
    //         .then(response => response)
    //         .catch(error => handleError(error))
    // }
}

const handleError = (error) => (error.response)
    ? error.response
    : {
        status: 503,
        data: {
            message: "No hay conexión contra el servicio"
        }
    }

const newUrl = (baseUrl, url) => (url) ? url : baseUrl

export const fnGetBaseUrl = () => baseUrl