import {
    fnGetUrl,
    fnPostUrl,
} from "../axios/Api"

export const getUsers = (nickname, id) => {
    const apiRequest = {
        endpoint: `/api/User/Nickname/${nickname}/${id}`,
    }
    return fnGetUrl(apiRequest)
}

export const getUserDetail = (nickname, id, userId) => {
    const apiRequest = {
        endpoint: `/api/User/Nickname/${nickname}/${id}/${userId}`,
    }
    return fnGetUrl(apiRequest)
}

export const getUsersRol = () => {
    const apiRequest = {
        endpoint: `/api/User/Roles`,
    }
    return fnGetUrl(apiRequest)
}

export const postUser = (data) => {
    const apiRequest = {
        endpoint: `/api/User/`,
        body: data,
    }
    return fnPostUrl(apiRequest)
}